import "echarts";
import { registerTheme } from "echarts";
import ECharts from "vue-echarts";
import $dayjs from "./days";
import lightTheme from "./themes/light-theme.json";

registerTheme("light-theme", lightTheme);
export default ECharts;

interface TooltipData {
  data: Array<number>;
  marker: string;
  seriesName: string;
}

const formatter = (tooltipData: TooltipData[]) => {
  const month = $dayjs(tooltipData[0].data[0]).format("MMMM YYYY");
  const values = tooltipData
    .map(
      (series) =>
        `${series.marker} ${
          series.seriesName
        } <strong>${series.data[1].toLocaleString("de")}</strong>`
    )
    .join("<br />");
  return `<strong>${month}</strong><br />${values}`;
};

export { formatter };
