import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/fonts/fonts.css";
import "@mdi/font/css/materialdesignicons.css";
import ECharts from "./plugins/echarts";
import { createPinia, PiniaVuePlugin } from "pinia";
import $dayjs from "./plugins/days";

Vue.config.productionTip = false;

Vue.component("v-chart", ECharts);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  router,
  vuetify,
  pinia,
  provide() {
    return {
      ["dayjs"]: $dayjs,
    };
  },
  render: (h) => h(App),
}).$mount("#app");
