import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";
import minMax from "dayjs/plugin/minMax";
import "dayjs/locale/de";

const $dayjs = dayjs;

$dayjs.extend(relativeTime);
$dayjs.extend(isBetween);
dayjs.extend(minMax);
$dayjs.locale("de");

export default $dayjs;
