import { defineStore } from "pinia";
import { useBreakpoints } from "@/composables/breakpoints";

export const useBreakpointsStore = defineStore({
  id: "breakpoints",
  state: () => {
    return {
      breakpoints: useBreakpoints(),
    };
  },
  getters: {
    isSmallDevice: (state) => state.breakpoints.type === "xs",
    width: (state) => state.breakpoints.width,
    type: (state) => state.breakpoints.type,
  },
});
