import { Dayjs } from "dayjs";
import $dayjs from "@/plugins/days";
import axios from "axios";
import { defineStore } from "pinia";
import colors from "./colors";

interface ProtestCategory {
  color: string;
  label: string;
}

interface ProtestEvent {
  id: string;
  date: string;
  date_normalized: string;
  year: string;
  month: string;
  total_participants: number;
  total_participants_dummy: string;
  city: string;
  forms: string[];
  claims: string[];
  prodat: boolean;
}

interface AggregatedProtestEvent {
  year: string;
  month: string;
  total_participants_dummy: string;
  forms: string[];
  claims: string[];
  prodat: boolean;
  event_count: number;
  total_participants: number;
}

interface ProtestEvents {
  protest_events: ProtestEvent[];
  aggregated_protest_events: AggregatedProtestEvent[];
}

interface ProtestEventsStoreState {
  protestEvents: ProtestEvents;
  nTotalProtestEvents: number;
  nDummyParticipants: number;
}

export { ProtestCategory };

export const useProtestEventsStore = defineStore({
  id: "protest-events",
  state: (): ProtestEventsStoreState => ({
    protestEvents: { protest_events: [], aggregated_protest_events: [] },
    nTotalProtestEvents: 0,
    nDummyParticipants: 0,
  }),
  getters: {
    cities: (state): ProtestCategory[] => {
      const cities = [
        ...new Set(
          state.protestEvents.protest_events
            .filter((event) => event.prodat === false)
            .map((event) => event.city)
        ),
      ];
      cities.sort((a, b) =>
        a.toLowerCase() > b.toLowerCase()
          ? 1
          : a.toLowerCase() < b.toLowerCase()
          ? -1
          : 0
      );
      return cities.map((city, index) => ({
        color: colors[index],
        label: city,
      }));
    },
    forms: (state): ProtestCategory[] =>
      [
        ...new Set(
          state.protestEvents.protest_events
            .filter((event) => event.prodat === false)
            .map((event) => event.forms)
            .flat()
        ),
      ].map((form, index) => ({
        color: colors[index],
        label: form,
      })),
    formsNational: (state): ProtestCategory[] =>
      [
        ...new Set(
          state.protestEvents.protest_events
            .filter((event) => event.prodat === true)
            .map((event) => event.forms)
            .flat()
        ),
      ].map((form, index) => ({
        color: colors[index],
        label: form,
      })),
    claims: (state): ProtestCategory[] =>
      [
        ...new Set(
          state.protestEvents.protest_events
            .filter((event) => event.prodat === false)
            .map((event) => event.claims)
            .flat()
        ),
      ].map((claim, index) => ({
        color: colors[index],
        label: claim,
      })),
    claimsNational: (state): ProtestCategory[] =>
      [
        ...new Set(
          state.protestEvents.protest_events
            .filter((event) => event.prodat === true)
            .map((event) => event.claims)
            .flat()
        ),
      ].map((claim, index) => ({
        color: colors[index],
        label: claim,
      })),
    filteredProtestEvents:
      (state) =>
      (
        cities: ProtestCategory[] = [],
        forms: ProtestCategory[] = [],
        claims: ProtestCategory[] = [],
        national: boolean,
        preAggregated: boolean
      ) => {
        const events: any = preAggregated
          ? state.protestEvents.aggregated_protest_events
          : state.protestEvents.protest_events;
        return events
          .filter((event: any) => event.prodat == national)
          .filter(
            (event: any) =>
              cities.length < 1 ||
              cities.map((city) => city.label).includes(event.city)
          )
          .filter(
            (event: any) =>
              forms.length < 1 ||
              event.forms.some((form: any) =>
                forms.map((form) => form.label).includes(form)
              )
          )
          .filter(
            (event: any) =>
              claims.length < 1 ||
              event.claims.some((claim: any) =>
                claims.map((claim) => claim.label).includes(claim)
              )
          );
      },
    minDate() {
      return (filteredProtestEvents: ProtestEvent[]): any => {
        const dates = filteredProtestEvents.map((e: any) => {
          if (e.month != "NA") {
            if ($dayjs(e.month + "-01", "YYYY-MM-DD").isValid()) {
              return $dayjs(e.month + "-01", "YYYY-MM-DD");
            }
          }
        }) as Dayjs[];
        return $dayjs.min(...dates.filter((d) => !!d));
      };
    },
    maxDate() {
      return (filteredProtestEvents: ProtestEvent[]): Dayjs => {
        const dates = filteredProtestEvents.map((e: any) => {
          if ($dayjs(e.month + "-01", "YYYY-MM-DD").isValid()) {
            return $dayjs(e.month + "-01", "YYYY-MM-DD");
          }
        }) as Dayjs[];
        return $dayjs.max(...dates.filter((d) => !!d));
      };
    },
    dateRange() {
      return (
        filteredProtestEvents: ProtestEvent[],
        isCityComparison: boolean,
        cities: ProtestCategory[] = []
      ) => {
        let start;
        let end;

        if (filteredProtestEvents.length === 0) return;

        if (filteredProtestEvents.length === 1) {
          // this is needed if only one date is available
          start = $dayjs(
            filteredProtestEvents[0].month + "-01",
            "YYYY-MM-DD"
          ).subtract(6, "month");
          end = $dayjs(
            filteredProtestEvents[0].month + "-01",
            "YYYY-MM-DD"
          ).add(6, "month");
        } else if (isCityComparison && cities.length > 1) {
          // only get overlapping ranges for Mittel-, und Großstädte
          const citiesMinDates = cities.map((city: ProtestCategory) => {
            return this.minDate(
              filteredProtestEvents.filter(
                (e: ProtestEvent) => e.city === city.label
              )
            );
          });
          const citiesMaxDates = cities.map((city: ProtestCategory) => {
            return this.maxDate(
              filteredProtestEvents.filter(
                (e: ProtestEvent) => e.city === city.label
              )
            );
          });
          start = $dayjs.max(citiesMinDates.filter((d) => !!d));
          end = $dayjs.min(citiesMaxDates.filter((d) => !!d));
        } else {
          // min and max date otherwise
          start = this.minDate(filteredProtestEvents);
          end = this.maxDate(filteredProtestEvents);

          if (Math.abs(start.diff(end, "month")) < 12) {
            const diffToYear = (12 - start.diff(end, "month")) / 2;
            start = start.subtract(diffToYear, "month");
            end = end.add(diffToYear, "month");
          }
        }

        const range = [];
        while (start.isBefore(end) || start.isSame(end)) {
          range.push(start.format("YYYY-MM"));
          start = start.add(1, "month");
        }
        return range;
      };
    },
    totalEvents() {
      return (
        cities: ProtestCategory[] = [],
        forms: ProtestCategory[] = [],
        claims: ProtestCategory[] = [],
        national: boolean
      ): number =>
        this.filteredProtestEvents(cities, forms, claims, national, false)
          .length;
    },
    totalEventsWithMissingParticipants() {
      return (
        cities: ProtestCategory[] = [],
        forms: ProtestCategory[] = [],
        claims: ProtestCategory[] = [],
        national: boolean
      ): number =>
        this.filteredProtestEvents(
          cities,
          forms,
          claims,
          national,
          false
        ).filter((e: ProtestEvent) => e.total_participants_dummy != "1").length;
    },
    aggregateData() {
      return (
        events: AggregatedProtestEvent[] | ProtestEvent[],
        observationUnit: string,
        dateRange: string[] = []
      ) => {
        if (events.length === 0) return [];
        const data: { [key: string]: number } = {};
        dateRange.map((date) => (data[date] = 0));

        events.map((event) => {
          if (event.month in data) {
            switch (observationUnit) {
              case "Absolute Anzahl an Teilnehmer:innen":
                if (
                  event.total_participants &&
                  event.total_participants_dummy == "1"
                ) {
                  data[event.month] =
                    data[event.month] + event.total_participants;
                }
                break;

              default:
                if ("event_count" in event) {
                  data[event.month] = data[event.month] + event.event_count;
                } else {
                  data[event.month] = data[event.month] + 1;
                }
            }
          }
        });
        return Object.entries(data).map(([key, value]) => [`${key}-01`, value]);
      };
    },
    protestEventsOverTime() {
      return (
        cities: ProtestCategory[] = [],
        forms: ProtestCategory[] = [],
        claims: ProtestCategory[] = [],
        observationUnit: string,
        national: boolean
      ) => {
        if (cities.length < 1) {
          const events = this.filteredProtestEvents(
            cities,
            forms,
            claims,
            national,
            true
          );
          const dateRange = this.dateRange(events, false);
          return {
            name: "Bundesweit",
            type: "line",
            color: "#a1cda8",
            showBackground: true,
            backgroundStyle: {
              color: "#efefef",
            },
            emphasis: {
              focus: "series",
            },
            data: this.aggregateData(events, observationUnit, dateRange),
          };
        } else {
          const dateRange = this.dateRange(
            this.filteredProtestEvents(cities, forms, claims, national, false),
            true,
            cities
          );
          return cities.map((city) => {
            const events = this.filteredProtestEvents(
              [city],
              forms,
              claims,
              national,
              false
            );
            return {
              name: city.label,
              type: "line",
              color: city.color,
              showBackground: true,
              backgroundStyle: {
                color: "#efefef",
              },
              emphasis: {
                focus: "series",
              },
              data: this.aggregateData(events, observationUnit, dateRange),
            };
          });
        }
      };
    },
    protestEventsGroupedByMonthAndCity() {
      return (
        cities: ProtestCategory[] = [],
        forms: ProtestCategory[] = [],
        claims: ProtestCategory[] = [],
        observationUnit: string,
        isStacked: boolean,
        national: boolean
      ) => {
        const dateRange = this.dateRange(
          this.filteredProtestEvents(cities, forms, claims, national, false),
          true,
          cities
        );
        if (cities.length < 1) return [];
        return cities.map((city) => {
          const events = this.filteredProtestEvents(
            [city],
            forms,
            claims,
            national,
            false
          );
          return {
            name: city.label,
            type: "bar",
            stack: isStacked ? "one" : null,
            color: city.color,
            showBackground: true,
            backgroundStyle: {
              color: "#efefef",
            },
            emphasis: {
              focus: "series",
            },
            data: this.aggregateData(events, observationUnit, dateRange),
          };
        });
      };
    },
    protestEventsGroupedByMonthAndForm() {
      return (
        cities: ProtestCategory[] = [],
        forms: ProtestCategory[] = [],
        claims: ProtestCategory[] = [],
        observationUnit: string,
        isStacked: boolean,
        national: boolean
      ) => {
        const dateRange = this.dateRange(
          this.filteredProtestEvents(cities, forms, claims, national, false),
          false
        );
        if (forms.length < 1) return [];
        return forms.map((form) => {
          let events = [];
          if (cities.length >= 1) {
            events = this.filteredProtestEvents(
              cities,
              [form],
              claims,
              national,
              false
            );
          } else {
            events = this.filteredProtestEvents(
              cities,
              [form],
              claims,
              national,
              true
            );
          }
          return {
            name: form.label,
            type: "bar",
            stack: isStacked ? "one" : null,
            color: form.color,
            showBackground: true,
            backgroundStyle: {
              color: "#efefef",
            },
            emphasis: {
              focus: "series",
            },
            data: this.aggregateData(events, observationUnit, dateRange),
          };
        });
      };
    },
    protestEventsGroupedByMonthAndClaim() {
      return (
        cities: ProtestCategory[] = [],
        forms: ProtestCategory[] = [],
        claims: ProtestCategory[] = [],
        observationUnit: string,
        isStacked: boolean,
        national: boolean
      ): any => {
        const dateRange = this.dateRange(
          this.filteredProtestEvents(cities, forms, claims, national, false),
          false
        );
        if (claims.length < 1) return [];
        return claims.map((claim) => {
          let events = [];
          if (cities.length >= 1) {
            events = this.filteredProtestEvents(
              cities,
              forms,
              [claim],
              national,
              false
            );
          } else {
            events = this.filteredProtestEvents(
              cities,
              forms,
              [claim],
              national,
              true
            );
          }
          return {
            name: claim.label,
            type: "bar",
            stack: isStacked ? "one" : null,
            color: claim.color,
            showBackground: true,
            backgroundStyle: {
              color: "#efefef",
            },
            emphasis: {
              focus: "series",
            },
            data: this.aggregateData(events, observationUnit, dateRange),
          };
        });
      };
    },
  },
  actions: {
    async fetchProtestEvents() {
      try {
        const response = await axios.get("/protest-data.json");

        this.protestEvents = response.data;

        this.nTotalProtestEvents = response.data.protest_events.length;

        this.nDummyParticipants =
          this.nTotalProtestEvents - this.protestEvents.protest_events.length;
      } catch (error) {
        // Todo how do we want to handle errors?
      }
    },
  },
});
