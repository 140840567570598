import Vue from "vue";
import VueRouter, { type RouteConfig } from "vue-router";

import ContactView from "@/views/ContactView.vue";
import MethodsView from "@/views/MethodsView.vue";
import HomeView from "../views/HomeView.vue";
import NationalAnalysisView from "@/views/NationalAnalysisView.vue";
import LocalAnalysisView from "@/views/LocalAnalysisView.vue";
import ImprintView from "@/views/ImprintView.vue";
import DataPrivacyView from "@/views/DataPrivacyView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/stadte-analyse/:stadt?",
    name: "LocalAnalysis",
    component: LocalAnalysisView,
  },
  {
    path: "/bundesweite-analyse",
    name: "NationalAnalysis",
    component: NationalAnalysisView,
  },
  {
    path: "/methods",
    name: "Methods",
    component: MethodsView,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: ImprintView,
  },
  {
    path: "/data-privacy",
    name: "DataPrivacy",
    component: DataPrivacyView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
