export default [
  "#a1cda8",
  "#F3C969",
  "#A9BCD0",
  "#E69597",
  "#b7ad99",
  "#D8D2E1",
  "#ecb0e1",
  "#E9B872",
  "#DFBE99",
  "#A9CEF4",
  "#F68E5F",
  "#E3D888",
  "#D1BECF",
  "#B5BD89",
  "#6DAEDB",
  "#f9ab55",
];
