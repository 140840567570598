import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#35415A",
        secondary: "#729EA1",
        accent: "#E09F3E",
        primary_light: "#F3F6F7",
      },
    },
  },
});
